import { apiUrls } from '~/data/defaults.json'
import CustomerMain from '~/views/customer/CustomerMain'
import Home from '~/views/Home'
import Main from '~/views/Main'
import { camelCase } from 'lodash-es'
import pluralize from 'pluralize'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const ContentPageView = () => import('~/views/contentPages/ContentPageView')
const CustomerLogin = () => import('~/views/customer/CustomerLogin')
const QuestionnaireUpdate = () => import('~/views/questionnaire/QuestionnaireUpdate')
const CustomerQuestionnaireSubmited = () => import('~/views/customer/CustomerQuestionnaireSubmited')
const AdviserQuestionnaireView = () => import('~/views/adviser/AdviserQuestionnaireView')
const BusinessApplicationPdfView = () => import('~/views/businessApplications/BusinessApplicationPdfView')

export const customerArea = 'customer'
export const adminArea = 'admin'
export const personalArea = 'personal'
export const legacyArea = 'legacy'
export const businessArea = 'business'
export const newBusinessArea = 'new-business'
export const processingArea = 'processing'
export const underwritingArea = 'underwriting'
export const pdfArea = 'pdf'

export const quoteRequestsPath = 'quote-requests'
export const healthcarePlansPath = 'healthcare-plans'
export const agenciesPath = 'agencies'
export const regionsPath = 'regions'
export const mtjCasesPath = 'mtj-cases'
export const quotePath = 'quote'
export const paymentDetailsPath = 'paymentDetails'
export const quotesPath = `${quotePath}s`
export const businessQuotesPath = `${businessArea}-${quotesPath}`
export const personalQuotesPath = `${personalArea}-${quotesPath}`
export const proposalsPath = 'proposals'
export const applicationPath = 'application'
export const applicationsPath = `${applicationPath}s`
export const businessApplicationsPath = `${businessArea}-${applicationsPath}`
export const personalApplicationsPath = `${personalArea}-${applicationsPath}`
export const businessApplicationPath = `${businessArea}-${applicationPath}`

export const factFindPath = 'fact-find'

export const factFindsPath = `${factFindPath}s`
export const indicativeQuotesPath = `indicative-${quotesPath}`
export const businessIndicativeQuotesPath = `${businessArea}-${indicativeQuotesPath}`
export const bundlesPath = 'bundles'
export const hospitalListsPath = 'hospital-lists'

const insurerPath = 'insurer'

export const insurerSchemesPath = `${insurerPath}-schemes`
export const insurersPath = `${insurerPath}s`
export const insurerHospitalListsPath = `${insurerPath}-${hospitalListsPath}`
export const insurerModuleGroupsPath = `${insurerPath}-module-groups`
export const insurerModulesPath = `${insurerPath}-modules`
export const insurerExcessAmountsPath = `${insurerPath}-excess-amounts`
export const productsPath = 'products'
export const templatesPath = 'templates'
export const contentPagesPath = 'content-pages'
export const usersPath = 'users'
export const customerNeedsPath = 'customer-needs'
export const notificationsPath = 'notifications'
export const settingsPath = 'settings'
export const dashboardPath = 'dashboard'
export const pagesPath = 'pages'
export const variationsPath = 'variations'
export const commentsPath = 'comments'
export const documentsPath = 'documents'
export const marketingPreferencesPath = 'marketingPreferences'
export const eventsPath = 'events'
export const queriesPath = 'queries'
export const queryTypesPath = 'query-types'
export const postcodeAreasPath = 'postcode-areas'
export const verificationLinksPath = 'verificationLinks'
export const factFindApiPath = 'factFind'
export const questionPath = 'question'
export const questionnairePath = `${questionPath}naire`
export const quoteGroupPath = `${quotePath}Group`
export const opportunityPath = 'opportunity'
export const recommendedCoverPath = 'recommendedCover'

export const underwritingPath = 'underwriting'
export const underwritingQuestionsPath = `${underwritingPath}-${questionPath}s`
export const underwritingQuestionAnswersPath = `${underwritingPath}-${questionPath}-answers`
export const underwritingRiskScoresPath = `${underwritingPath}-risk-scores`

export const imagesPath = 'images'

export const discountsPath = 'discounts'
export const insurerDiscountsPath = `${insurerPath}-${discountsPath}`
export const memberMixDiscountsPath = `member-mix-${discountsPath}`
export const tenureDiscountsPath = `tenure-${discountsPath}`
export const midTermLoadingsPath = 'mid-term-loadings'

export const minimumPremiumsPath = 'minimum-premiums'

const benefitPath = 'benefit'
export const benefitsPath = `${benefitPath}s`
export const benefitCategoriesPath = `${benefitPath}-categories`

export const medicalConditionGroupsPath = 'medical-condition-groups'

const modulePath = 'module'
export const modulesPath = `${modulePath}s`
export const moduleGroupsPath = `${modulePath}-groups`

export const discountPath = 'discount'
export const calculateDiscountPath = `calculate-${discountPath}`
export const newPath = 'new'
export const importPath = 'import'
export const updatePath = 'update'
export const assignPath = 'assign'
export const processPath = 'process'
export const validatePath = 'validate'
export const submittedPath = 'submitted'
export const applyPath = 'apply'

export const verifyPath = 'verify'
export const updatePasswordPath = 'update-password'
export const loginPath = 'login'
export const loggedOutPath = 'loggedout'
export const helpPath = 'help'
export const customerBusinessApplicationPath = `${apiUrls.businessApplicationVerificationLinks}/${applicationPath}`

const getMeta = (entityName: string, apiUrl: string, updateUrl?: string, isPdf?: boolean, isQuestionnaire?: boolean) => {
    return {
        meta: {
            entityName,
            apiUrl,
            updateUrl: updateUrl ?? apiUrl,
            isPdf,
            isQuestionnaire
        }
    }
}

interface IParams {
    entityName: string
    entityAlias?: string
    path: string
    apiUrl: string
    createRouteName?: string
    canCreate?: boolean
    canUpdate?: boolean
    additionalRoutes?: RouteConfig[]
}

const getCrudRoutes = (
    {
        entityName,
        path,
        apiUrl,
        canCreate = true,
        canUpdate = true,
        additionalRoutes = [],
        entityAlias,
        createRouteName
    }: IParams) => {

    const name = entityAlias ?? entityName

    const meta = getMeta(name, apiUrl)

    const normalisedName = entityName.replace(/\S+/g, str => str.charAt(0).toUpperCase() + str.substring(1).toLowerCase()).replaceAll(' ', '')
    const pluralisedName = pluralize(normalisedName)
    const camelCasedName = camelCase(pluralisedName)

    const plural = pluralize(name)

    const routes: RouteConfig[] = [
        {
            path: path,
            name: plural,
            component: () => import(`~/views/${camelCasedName}/${pluralisedName}Grid`),
            ...meta
        }
    ]

    if (canCreate)
        routes.push({
            path: `${path}/${newPath}`,
            name: createRouteName ?? `New ${name.toLowerCase()}`,
            component: () => import(`~/views/${camelCasedName}/${normalisedName}New`),
            ...meta
        })

    const entityRoute: RouteConfig = {
        path: `${path}/:id`,
        name: `${name} summary`,
        component: () => import(`~/views/${camelCasedName}/${normalisedName}View`),
        ...meta,
        children: []
    }

    if (canUpdate)
        entityRoute.children?.push({
            path: updatePath,
            name: `Update ${name.toLowerCase()}`,
            component: () => import(`~/views/${camelCasedName}/${normalisedName}Update`),
            ...meta
        })

    if (additionalRoutes.length > 0)
        entityRoute.children?.push(...additionalRoutes)

    routes.push(entityRoute)

    return routes
}

export const fromUserIdPath = 'FromUserId'
const fromUserIdUrl = `${apiUrls.questionnaireVerificationLinks}/${fromUserIdPath}`

const bundleRoutes = getCrudRoutes(
    {
        entityName: 'Bundle',
        path: bundlesPath,
        apiUrl: apiUrls.bundles
    })
const mtjCaseRoutes = getCrudRoutes(
    {
        entityName: 'MTJ case',
        entityAlias: 'Business MTJ case',
        path: mtjCasesPath,
        apiUrl: apiUrls.mtjCases,
        canCreate: false,
        canUpdate: false
    }
)

const businessQuoteRoutes = getCrudRoutes(
    {
        entityName: 'Business quote',
        path: businessQuotesPath,
        apiUrl: apiUrls.businessQuotes,
        canCreate: false,
        canUpdate: false
    })
const businessApplicationRoutes = getCrudRoutes(
    {
        entityName: 'Business application',
        path: businessApplicationsPath,
        apiUrl: apiUrls.businessApplications,
        canCreate: false,
        canUpdate: false
    })
const personalQuoteRoutes = getCrudRoutes(
    {
        entityName: 'Personal quote',
        path: personalQuotesPath,
        apiUrl: apiUrls.personalQuotes,
        canCreate: false,
        canUpdate: false
    })
const personalApplicationRoutes = getCrudRoutes(
    {
        entityName: 'Personal application',
        path: personalApplicationsPath,
        apiUrl: apiUrls.personalApplications,
        canCreate: false,
        canUpdate: false
    })

const routes: RouteConfig[] = [
    {
        path: '/',
        component: Home,
        children: [
            {
                path: processingArea,
                redirect: `/${processingArea}/${businessQuotesPath}`,
                component: Main,
                children: [
                    ...getCrudRoutes(
                        {
                            entityName: 'Business quote',
                            path: businessQuotesPath,
                            apiUrl: apiUrls.businessQuotes
                        })
                ]
            },
            {
                path: businessArea,
                redirect: `/${businessArea}/${quotesPath}`,
                component: Main,
                children: [
                    {
                        path: `${helpPath}/:id`,
                        component: ContentPageView,
                        ...getMeta('Help', apiUrls.contentPages)
                    },

                    ...getCrudRoutes(
                        {
                            entityName: 'Fact find',
                            path: factFindsPath,
                            apiUrl: apiUrls.factFinds,
                            additionalRoutes: [
                                {
                                    path: quotePath,
                                    name: 'New quote from fact find',
                                    component: () => import('~/views/factFinds/FactFindQuoteNew'),
                                    ...getMeta('Fact find', apiUrls.factFinds, apiUrls.businessQuotes)
                                }
                            ]
                        }
                    ),

                    ...getCrudRoutes(
                        {
                            entityName: 'Business indicative quote',
                            entityAlias: 'Indicative quote',
                            path: indicativeQuotesPath,
                            apiUrl: apiUrls.businessIndicativeQuotes,
                            additionalRoutes: [
                                {
                                    path: quotePath,
                                    name: 'Convert to formal quote',
                                    component: () => import('~/views/businessIndicativeQuotes/BusinessIndicativeQuoteConvert'),
                                    ...getMeta('Business indicative quote', apiUrls.businessIndicativeQuotes, apiUrls.businessQuotes)
                                }
                            ]
                        }),

                    {
                        path: `${quotesPath}/${importPath}`,
                        name: 'Import quote',
                        component: () => import('~/views/businessQuotes/BusinessQuoteImport'),
                        ...getMeta('Quote', apiUrls.businessQuotes)
                    },
                    ...getCrudRoutes(
                        {
                            entityName: 'Business Quote',
                            entityAlias: 'Quote',
                            path: quotesPath,
                            apiUrl: apiUrls.businessQuotes,
                            additionalRoutes: [
                                {
                                    path: applyPath,
                                    name: 'Apply with quote',
                                    component: () => import('~/views/businessQuotes/BusinessQuoteApply'),
                                    ...getMeta('Quote', apiUrls.businessQuotes, apiUrls.businessApplications)
                                },
                                {
                                    path: factFindPath,
                                    name: 'Add fact find',
                                    component: () => import('~/views/businessQuotes/BusinessQuoteFactFindNew'),
                                    ...getMeta('Quote', apiUrls.businessQuotes, apiUrls.factFinds)
                                }
                            ]
                        }
                    ),
                    ...getCrudRoutes(
                        {
                            entityName: 'Business Application',
                            entityAlias: 'Application',
                            path: applicationsPath,
                            apiUrl: apiUrls.businessApplications,
                            canCreate: false
                        })
                ]
            },
            {
                path: personalArea,
                redirect: `/${personalArea}/${quotesPath}`,
                component: Main,
                children: [
                    {
                        path: `${helpPath}/:id`,
                        component: ContentPageView,
                        ...getMeta('Help', apiUrls.contentPages)
                    },

                    ...bundleRoutes,

                    ...getCrudRoutes(
                        {
                            entityName: 'Personal Quote',
                            entityAlias: 'Quote',
                            path: quotesPath,
                            apiUrl: apiUrls.personalQuotes,
                            additionalRoutes: [
                                {
                                    path: applyPath,
                                    name: 'Apply with quote',
                                    component: () => import('~/views/personalQuotes/PersonalQuoteApply'),
                                    ...getMeta('Quote', apiUrls.personalQuotes, apiUrls.personalApplications)
                                }
                            ]
                        }
                    ),
                    ...getCrudRoutes(
                        {
                            entityName: 'Personal Application',
                            entityAlias: 'Application',
                            path: applicationsPath,
                            apiUrl: apiUrls.personalApplications,
                            canCreate: false
                        }),

                    {
                        path: `${quotesPath}/:id/${questionnairePath}`,
                        component: AdviserQuestionnaireView,
                        ...getMeta('Quote', apiUrls.personalQuotes),
                        children: [
                            {
                                path: updatePath,
                                component: QuestionnaireUpdate,
                                ...getMeta('Quote', apiUrls.personalQuotes, apiUrls.adviserSwitchQuestionnaire)
                            },
                            {
                                path: submittedPath,
                                component: CustomerQuestionnaireSubmited
                            }
                        ]
                    },
                    {
                        path: `${applicationsPath}/:id/${questionnairePath}`,
                        component: AdviserQuestionnaireView,
                        ...getMeta('Application', apiUrls.personalApplications),
                        children: [
                            {
                                path: updatePath,
                                component: QuestionnaireUpdate,
                                ...getMeta('Application', apiUrls.personalApplications, apiUrls.adviserApplicationQuestionnaire)
                            },
                            {
                                path: submittedPath,
                                component: CustomerQuestionnaireSubmited
                            }
                        ]
                    }
                ]
            },
            {
                path: newBusinessArea,
                redirect: `/${newBusinessArea}/${personalApplicationsPath}`,
                component: Main,
                children: [
                    ...personalQuoteRoutes,
                    ...personalApplicationRoutes,
                    ...businessQuoteRoutes,
                    ...businessApplicationRoutes
                ]
            },
            {
                path: underwritingArea,
                redirect: `/${underwritingArea}/${personalQuotesPath}`,
                component: Main,
                children: [
                    ...mtjCaseRoutes,
                    ...personalQuoteRoutes,
                    ...personalApplicationRoutes
                ]
            },
            {
                path: adminArea,
                redirect: `/${adminArea}/${dashboardPath}`,
                component: Main,
                children: [
                    {
                        path: dashboardPath,
                        name: 'Dashboard',
                        component: () => import('~/views/DashboardView'),
                    },

                    {
                        path: settingsPath,
                        name: 'Application settings',
                        component: () => import('~/views/SettingsView'),
                        ...getMeta('Settings', apiUrls.settings)
                    },

                    ...getCrudRoutes(
                    {
                        entityName: 'Region',
                        path: regionsPath,
                        apiUrl: apiUrls.regions
                    }),
                    ...getCrudRoutes(
                    {
                        entityName: 'Agency',
                        path: agenciesPath,
                        apiUrl: apiUrls.agencies
                    }),

                    ...getCrudRoutes(
                    {
                        entityName: 'Business indicative quote',
                        path: businessIndicativeQuotesPath,
                        apiUrl: apiUrls.businessIndicativeQuotes,
                        canCreate: false,
                        canUpdate: false
                    }),
                    ...getCrudRoutes(
                    {
                        entityName: 'Fact find',
                        entityAlias: 'Business fact find',
                        path: factFindsPath,
                        apiUrl: apiUrls.factFinds,
                        canCreate: false,
                        canUpdate: false
                    }),
                    ...getCrudRoutes(
                    {
                        entityName: 'Business quote request',
                        path: quoteRequestsPath,
                        apiUrl: apiUrls.businessQuoteRequests,
                        canCreate: false,
                        canUpdate: false
                    }),
                    ...getCrudRoutes(
                    {
                        entityName: 'Business healthcare plan',
                        path: healthcarePlansPath,
                        apiUrl: apiUrls.businessHealthcarePlans,
                        canCreate: false,
                        canUpdate: false
                    }),
                    ...mtjCaseRoutes,
                    ...businessQuoteRoutes,
                    ...businessApplicationRoutes,
                    ...personalQuoteRoutes,
                    ...personalApplicationRoutes,
                    ...bundleRoutes,
                    ...getCrudRoutes(
                        {
                            entityName: 'Query type',
                            path: queryTypesPath,
                            apiUrl: apiUrls.queryTypes
                        }),
                    ...getCrudRoutes(
                        {
                            entityName: 'Postcode area',
                            path: postcodeAreasPath,
                            apiUrl: apiUrls.postcodeAreas
                        }),
                    ...getCrudRoutes(
                        {
                            entityName: 'Insurer',
                            path: insurersPath,
                            apiUrl: apiUrls.insurers
                        }),
                    ...getCrudRoutes(
                        {
                            entityName: 'Insurer hospital list',
                            path: insurerHospitalListsPath,
                            apiUrl: apiUrls.insurerHospitalLists
                        }),
                    ...getCrudRoutes(
                        {
                            entityName: 'Insurer module group',
                            path: insurerModuleGroupsPath,
                            apiUrl: apiUrls.insurerModuleGroups
                        }),
                    ...getCrudRoutes(
                        {
                            entityName: 'Insurer excess amount',
                            path: insurerExcessAmountsPath,
                            apiUrl: apiUrls.insurerExcessAmounts
                        }),
                    ...getCrudRoutes(
                        {
                            entityName: 'Insurer module',
                            path: insurerModulesPath,
                            apiUrl: apiUrls.insurerModules
                        }),
                    ...getCrudRoutes(
                        {
                            entityName: 'Insurer scheme',
                            path: insurerSchemesPath,
                            apiUrl: apiUrls.insurerSchemes
                        }),
                    ...getCrudRoutes(
                        {
                            entityName: 'Product',
                            path: productsPath,
                            apiUrl: apiUrls.products
                        }),
                    ...getCrudRoutes(
                        {
                            entityName: 'Template',
                            path: templatesPath,
                            apiUrl: apiUrls.templates
                        }),
                    ...getCrudRoutes(
                        {
                            entityName: 'Content page',
                            path: contentPagesPath,
                            apiUrl: apiUrls.contentPages
                        }),
                    ...getCrudRoutes(
                        {
                            entityName: 'User',
                            path: usersPath,
                            apiUrl: apiUrls.users
                        }),
                    ...getCrudRoutes(
                        {
                            entityName: 'Customer need',
                            path: customerNeedsPath,
                            apiUrl: apiUrls.customerNeeds
                        }),
                    ...getCrudRoutes(
                        {
                            entityName: 'Notification',
                            path: notificationsPath,
                            apiUrl: apiUrls.notifications,
                            canCreate: false,
                            canUpdate: false
                        }),
                    ...getCrudRoutes(
                        {
                            entityName: 'Underwriting question',
                            path: underwritingQuestionsPath,
                            apiUrl: apiUrls.underwritingQuestions
                        }),
                    ...getCrudRoutes(
                        {
                            entityName: 'Underwriting question answer',
                            path: underwritingQuestionAnswersPath,
                            apiUrl: apiUrls.underwritingQuestionAnswers
                        }),
                    ...getCrudRoutes(
                        {
                            entityName: 'Underwriting risk score',
                            path: underwritingRiskScoresPath,
                            apiUrl: apiUrls.underwritingRiskScores
                        }),
                    ...getCrudRoutes(
                        {
                            entityName: 'Medical condition group',
                            path: medicalConditionGroupsPath,
                            apiUrl: apiUrls.medicalConditionGroups
                        }),
                    ...getCrudRoutes(
                        {
                            entityName: 'Module',
                            path: modulesPath,
                            apiUrl: apiUrls.modules
                        }),
                    ...getCrudRoutes(
                        {
                            entityName: 'Module group',
                            path: moduleGroupsPath,
                            apiUrl: apiUrls.moduleGroups
                        }),
                    ...getCrudRoutes(
                        {
                            entityName: 'Benefit',
                            path: benefitsPath,
                            apiUrl: apiUrls.benefits
                        }),
                    ...getCrudRoutes(
                        {
                            entityName: 'Benefit category',
                            path: benefitCategoriesPath,
                            apiUrl: apiUrls.benefitCategories
                        }),
                    ...getCrudRoutes(
                        {
                            entityName: 'Image',
                            path: imagesPath,
                            apiUrl: apiUrls.images
                        }),
                    ...getCrudRoutes(
                        {
                            entityName: 'Insurer discount',
                            path: insurerDiscountsPath,
                            apiUrl: apiUrls.insurerDiscounts
                        }),
                    ...getCrudRoutes(
                        {
                            entityName: 'Tenure discount',
                            path: tenureDiscountsPath,
                            apiUrl: apiUrls.tenureDiscounts
                        }),
                    ...getCrudRoutes(
                        {
                            entityName: 'Member mix discount',
                            path: memberMixDiscountsPath,
                            apiUrl: apiUrls.memberMixDiscounts
                        }),
                    ...getCrudRoutes(
                        {
                            entityName: 'Minimum premium',
                            path: minimumPremiumsPath,
                            apiUrl: apiUrls.minimumPremiums
                        }),
                    ...getCrudRoutes(
                        {
                            entityName: 'Mid term loading',
                            path: midTermLoadingsPath,
                            apiUrl: apiUrls.midTermLoadings
                        })
                ]
            }
        ]
    },
    {
        path: `/:area/${pdfArea}`,
        component: Main,
        children: [
            {
                path: `${quoteRequestsPath}/:id`,
                component: () => import('~/views/businessQuoteRequests/BusinessQuoteRequestPdfView'),
                ...getMeta('Quote request', apiUrls.businessQuoteRequests, undefined, true)
            },
            {
                path: `${businessQuotesPath}/:id`,
                component: () => import('~/views/businessQuotes/BusinessQuotePdfView'),
                ...getMeta('Quote', apiUrls.businessQuotes, undefined, true)
            },
            {
                path: `${businessQuotesPath}/:id/${factFindPath}`,
                component: () => import('~/views/factFinds/FactFindPdfView'),
                ...getMeta('Fact find', apiUrls.businessQuotes, undefined, true)
            },
            {
                path: `${businessApplicationsPath}/:id`,
                component: BusinessApplicationPdfView,
                ...getMeta('Application', apiUrls.businessApplications, undefined, true)
            },
            {
                path: `${customerArea}/${businessApplicationPath}`,
                component: BusinessApplicationPdfView,
                ...getMeta('Application', customerBusinessApplicationPath, undefined, true)
            },
            {
                path: `${mtjCasesPath}/:id`,
                component: () => import('~/views/mtjCases/MtjCasePdfView'),
                ...getMeta('MTJ case', apiUrls.mtjCases, undefined, true)
            },
            {
                path: `${personalQuotesPath}/:id`,
                component: () => import('~/views/personalQuotes/PersonalQuotePdfView'),
                ...getMeta('Quote', apiUrls.personalQuotes, undefined, true)
            },
            {
                path: `${personalQuotesPath}/:id/sales-aid`,
                component: () => import('~/views/personalQuotes/SalesAidPdfView'),
                ...getMeta('Quote', apiUrls.personalQuotes, undefined, true)
            },
            {
                path: `${personalApplicationsPath}/:id`,
                component: () => import('~/views/personalApplications/PersonalApplicationPdfView'),
                ...getMeta('Application', apiUrls.personalApplications, undefined, true)
            }
        ]
    },
    {
        path: `/${customerArea}`,
        component: CustomerMain,
        children: [
            {
                path: loggedOutPath,
                component: () => import('~/views/customer/CustomerLoggedOut')
            },
            {
                path: `${loginPath}/:id`,
                component: CustomerLogin,
                ...getMeta('Login', apiUrls.questionnaireVerificationLinks, undefined, undefined, true)
            },
            {
                path: `${verifyPath}/:id`,
                component: CustomerLogin,
                ...getMeta('Verify', apiUrls.businessApplicationVerificationLinks)
            },
            {
                path: updatePasswordPath,
                component: () => import('~/views/customer/CustomerUpdatePassword'),
                ...getMeta('Details', fromUserIdUrl)
            },
            {
                path: helpPath,
                component: () => import('~/views/customer/CustomerQuestionnaireHelp')
            },
            {
                path: businessApplicationPath,
                component: () => import('~/views/customer/CustomerBusinessApplicationView'),
                ...getMeta('Application', customerBusinessApplicationPath),
                children: [
                    {
                        path: verifyPath,
                        component: () => import('~/views/customer/CustomerBusinessApplicationVerify'),
                        ...getMeta('Application', customerBusinessApplicationPath)
                    },
                    {
                        path: submittedPath,
                        component: () => import('~/views/customer/CustomerBusinessApplicationSubmitted'),
                        ...getMeta('Application', customerBusinessApplicationPath)
                    }
                ]
            },
            {
                path: questionnairePath,
                component: () => import('~/views/customer/CustomerQuestionnaireView'),
                ...getMeta('Details', fromUserIdUrl),
                children: [
                    {
                        path: updatePath,
                        component: QuestionnaireUpdate,
                        ...getMeta('Questionnaire', fromUserIdUrl, apiUrls.customerQuestionnaire)
                    },
                    {
                        path: submittedPath,
                        component: CustomerQuestionnaireSubmited
                    }
                ]
            },
            {
                path: 'finder',
                component: () => import('~/views/PartnerFinderView')
            }
        ]
    },
    {
        path: `/${verifyPath}/${businessApplicationsPath}/:id`,
        redirect: `/${customerArea}/${verifyPath}/:id`
    },
    {
        path: '/disabled',
        component: () => import('~/views/DisabledView')
    },
    {
        path: '*',
        component: () => import('~/views/NotFoundView')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: 'active',
    routes: routes
})

router.beforeEach((to, from, next) => {
    const parts = document.title.split(' - ')
    const routeName = to.name

    if (routeName)
        document.title = `${parts[0]} - ${routeName}`

    next()
})

export default router
